import { ExpenseType } from "./expense-type";
import { PaymentCondition } from "./paymentCondition";

export class CashierEventType {
    id?: string;
    description: string;
    expenseTypeId?: string;
    expenseType?: ExpenseType;
    /** Indicate if the event was paid(true) or received(false) */
    isPay?: boolean;
    /** If true, the event will be calculated into DRE report */
    dre?: boolean;
    /** 
     * - `true` - event for cashier
     * - `false` - event for banks
     */
    financial?: boolean;

    constructor(apiObj: any) {
        this.description = apiObj.description;
        this.isPay = [true, undefined].includes(apiObj.typeEvent);
        this.dre = Boolean(apiObj.dre);
        this.financial = Boolean(apiObj.financial);
        if (apiObj.expenseType) {
            this.expenseTypeId = apiObj.expenseType;
        }
        if (apiObj._id) {
            this.id = apiObj._id;
        }
    }

    toAPI() {
        let apiObj = {
            description: this.description,
            typeEvent: this.isPay,
            dre: this.dre,
            financial: this.financial
        }
        if (this.expenseTypeId) {
            apiObj['expenseType'] = this.expenseTypeId;
        }
        if (this.id) {
            /**
             * do not utilize Object.defineProperty to assign _id
             * because _id property will not be setted into form using FormGroup.patchValue or FormGroup.setValue
             */
            apiObj['_id'] = this.id;
        }
        return apiObj;
    }

}


export interface ApiEvent {
    "_id"?: string;
    "available"?: 0 | 1;
    "event"?: string;
    "value"?: number;
    "paymentConditions"?: string;
    "createdAt"?: string;
    "updatedAt"?: string;
    "status"?: 0 | 1;
    "bank"?: string;
    "moveDate": string;
    "observation"?: string;
    "balance"?: number;
}

export class CashierEvent {
    _id: string
    createdAt: Date;
    value: number;
    paymentConditionId: string;
    eventTypeId: string;

    paymentCondition?: PaymentCondition;
    eventType?: CashierEventType;
    /** 0 - canceled, 1- active */
    status?: 0 | 1;
    bank?: string;
    moveDate: Date;
    observation?: string;
    balance?: number;

    constructor(apiObj: ApiEvent) {
        this.balance = apiObj.balance || 0;
        if (apiObj.createdAt) {
            this.createdAt = new Date(apiObj.createdAt);
        }
        if (apiObj.paymentConditions) {
            this.paymentConditionId = apiObj.paymentConditions;
        }
        if (apiObj.event) {
            this.eventTypeId = apiObj.event;
        }
        if (apiObj.value) {
            this.value = apiObj.value;
        }
        if (apiObj.status !== undefined) {
            this.status = apiObj.status;
        }
        if (apiObj.bank) {
            this.bank = apiObj.bank;
        }
        if (apiObj._id) {
            this._id = apiObj._id;
        }
        if (apiObj.moveDate) {
            this.moveDate = new Date(apiObj.moveDate);
        }
        if (apiObj.observation) {
            this.observation = apiObj.observation;
        }
    }

    toAPI(): ApiEvent {
        let apiObj: ApiEvent = {
            paymentConditions: this.paymentConditionId,
            value: this.value,
            moveDate: null
        }
        if(this.moveDate){
            apiObj.moveDate = this.moveDate.valueOf() ? this.moveDate.toISOString() : new Date().toISOString()
        } else {
            apiObj.moveDate = this.createdAt.valueOf() ? this.createdAt.toISOString() : new Date().toISOString()
        }
        if (this.eventTypeId) {
            apiObj.event = this.eventTypeId;
        }
        if (this.bank) {
            apiObj.bank = this.bank;
        }
        if (this.status !== undefined) {
            apiObj.status = this.status;
        }
        if(this.observation !== undefined){
            apiObj.observation = this.observation;
        }
        if(this.balance !== undefined){
            apiObj.balance = this.balance;
        }
        return apiObj;
    }

}
