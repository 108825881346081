import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, firstValueFrom, map } from 'rxjs';
import { environment } from '../../environments/environment';
import { CashierEventType } from '../interface/cashier-event';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class CashierEventTypeService {

  private _isFromMatrix = true;


  constructor(
    private dataService: DataService,
    private _http: HttpClient
  ) { }


  public async create(eventType: CashierEventType) {
    const url = `${environment.mkgoURL}/api/v1/event`;
    const options = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const body = JSON.stringify(eventType.toAPI());
    let resp: any = await this._http.post(url, body, options).pipe(first()).toPromise();
    return resp;
  }

  public async getAll(cnpj?: string): Promise<CashierEventType[]> {
    const url = `${environment.mkgoURL}/api/v1/event`;
    const options = await firstValueFrom(this.dataService.httpOptions(cnpj || this._isFromMatrix));
    const resp = await this._http.get(url, options).pipe(
      first(),
      map((resp: any) => resp.events.filter(ev => ev.available).map(ev => new CashierEventType(ev))),
    ).toPromise();
    return resp || [];
  }

  /** @todo test */
  public async getById(id: string) {
    const url = `${environment.mkgoURL}/api/v1/event/${id}`;
    const options = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const resp = await this._http.get(url, options).pipe(first()).toPromise();
    return resp;
  }

  public async update(eventType: CashierEventType) {
    const url = `${environment.mkgoURL}/api/v1/event/${eventType.id}`;
    const options = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const body = JSON.stringify(eventType.toAPI());
    const resp = await this._http.put(url, body, options).pipe(first()).toPromise();
    return resp;
  }

  public async delete(id: string) {
    const url = `${environment.mkgoURL}/api/v1/event/${id}`;
    const options = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const resp = await this._http.delete(url, options).pipe(first()).toPromise();
    return resp;
  }

}

